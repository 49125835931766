const gotosection = document.querySelectorAll('.gotosection') as NodeListOf<HTMLDivElement>;
gotosection.forEach(element => {
  element.addEventListener("click", function(ev) {
    const navElement = document.querySelector('.navbar') as HTMLElement;
    const navHeight = navElement.clientHeight;
    const id = '#' + element.getAttribute('data-action');
    const formtyElement = document.querySelector(id) as HTMLElement;
    if (formtyElement) {
      const scrollTo = formtyElement.getBoundingClientRect().top + document.body.scrollTop - navHeight;
      document.body.scrollTo({
        top: scrollTo,
        behavior: 'smooth'
      });
    }
  });
});